import React, { createRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from './MultipleContentFrameContainer.scss';
import { LOADING_MODE, PERSISTENCE_MODE } from './constants.js';
import { setOfflineDownloadProgress } from '../../redux/actions/offlineContentPlayer';

function MultipleFrameContainer({
  frames,
  customClassName = '',
  currentFrameIndex = 0,
  loadingMode = LOADING_MODE.LOAD_ON_NAVIGATION,
  persistenceMode = PERSISTENCE_MODE.PERSIST_NO_FRAMES,
  setOfflineDownloadProgressAction
}) {
  const ref = useRef([]);

  const iframeRefs = useMemo(
    () =>
      frames.reduce(
        (acc, _, index) => ({
          ...acc,
          [index]: createRef()
        }),
        {}
      ),
    [frames.length]
  );

  if (frames.length === 0) {
    return null;
  }

  const handleIframeLoad = (unitId, activityId, index) => {
    let counter = 0;
    const maxAttempts = 5;

    const intervalId = setInterval(() => {
      try {
        const contentWindow = iframeRefs[index].current?.contentWindow;

        if (contentWindow?.LOInfo) {
          clearInterval(intervalId);
          setOfflineDownloadProgressAction({ unitId, activityId, downloadStatus: 'success' });
        } else if (counter >= maxAttempts) {
          clearInterval(intervalId);
          setOfflineDownloadProgressAction({ unitId, activityId, downloadStatus: 'failure' });
        } else {
          setOfflineDownloadProgressAction({ unitId, activityId, downloadStatus: 'downloading' });
        }
        counter += 1;
      } catch (error) {
        clearInterval(intervalId);
        setOfflineDownloadProgressAction({ unitId, activityId, downloadStatus: 'failure' });
        console.error('Error accessing iframe content:', error);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  };

  if (loadingMode === LOADING_MODE.PRELOAD_ALL) {
    return (
      <div className={styles.container}>
        {frames.map(({ title, src, activityId, unitId, ...props }, index) => (
          <iframe
            ref={iframeRefs[index]}
            onLoad={() => handleIframeLoad(unitId, activityId, index)}
            key={index}
            className={`${customClassName} ${index === currentFrameIndex ? styles.show : styles.hide}`}
            title={title}
            src={src}
            {...props}
          />
        ))}
      </div>
    );
  }

  if (loadingMode === LOADING_MODE.LOAD_ON_NAVIGATION) {
    if (persistenceMode === PERSISTENCE_MODE.PERSIST_ALL_FRAMES) {
      const activeFrame = frames[currentFrameIndex];
      let activeFrameIndex = ref.current.findIndex(frame => frame.src === activeFrame.src);

      if (ref.current.length === 0 || activeFrameIndex < 0) {
        ref.current.push(activeFrame);
        activeFrameIndex = ref.current.length - 1;
      }

      return (
        <div className={styles.container}>
          {ref.current.map(({ title, src, ...props }, index) => (
            <iframe
              key={index}
              className={`${customClassName} ${index === activeFrameIndex ? styles.show : styles.hide}`}
              title={title}
              src={src}
              {...props}
            />
          ))}
        </div>
      );
    }

    const { title, src, ...props } = frames[currentFrameIndex];
    return src ? (
      <div className={styles.container}>
        <iframe className={customClassName} title={title} src={src} {...props} />
      </div>
    ) : null;
  }
}

MultipleFrameContainer.propTypes = {
  frames: PropTypes.array,
  customClassName: PropTypes.string,
  currentFrameIndex: PropTypes.number,
  loadingMode: PropTypes.string,
  persistenceMode: PropTypes.string,
  setOfflineDownloadProgressAction: PropTypes.func
};

export default compose(
  connect(() => ({}), {
    setOfflineDownloadProgressAction: setOfflineDownloadProgress
  })
)(MultipleFrameContainer);
