import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';
import Checkbox from '@oup/shared-front-end/src/components/Checkbox/Checkbox.js';
import UnitItem from './UnitItem';
import withLocalizedContent from '../../language/withLocalizedContent.js';
// Styles
import styles from './UnitList.scss';

function UnitList({
  units = [],
  downloadButton = false,
  downloadCallback,
  localizedContent: { productDownload: offlineContent },
  userId
}) {
  const [isPrintViewSelected, setIsPrintViewSelected] = useState(false);
  const [downloadUnitInProgress, setDownloadUnitInProgress] = useState(null);

  return (
    <div className={styles.unitsContainer}>
      {units.map(unit => (
        <div
          className={classNames(styles.unitWrapper, {
            [styles.hasPrintView]: unit.hasAnyActivityWithPrintView && !unit.userId?.includes(userId)
          })}
          key={unit.uid}
        >
          <UnitItem
            unit={unit}
            downloadButton={downloadButton}
            downloadCallback={downloadCallback}
            isPrintViewSelected={isPrintViewSelected}
            userId={userId}
            setDownloadUnitInProgress={setDownloadUnitInProgress}
            downloadUnitInProgress={downloadUnitInProgress}
          />
          {unit.hasAnyActivityWithPrintView && !unit.userId?.includes(userId) && (
            <div className={styles.printViewCheckboxContainer}>
              <div className={styles.printViewCheckboxSubContainer}>
                <Checkbox
                  key={`${unit.uid}-checkbox`}
                  label={offlineContent.include_print_pages}
                  value={isPrintViewSelected}
                  checked={isPrintViewSelected}
                  onChange={() => setIsPrintViewSelected(!isPrintViewSelected)}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

UnitList.propTypes = {
  units: PropTypes.array.isRequired,
  downloadButton: PropTypes.bool,
  downloadCallback: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  userId: PropTypes.string
};

export default compose(withLocalizedContent('productDownload'))(UnitList);
