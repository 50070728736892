export const getActivitiesToRemove = (units, userId, unitsIds = []) => {
  const deletedActivities = [];
  const preservedActivities = [];

  const shouldRemoveUnitActivities = unit => {
    const matchesUser = unit.userId === userId;
    const matchesUnit = !unitsIds?.length || unitsIds.includes(unit.id);
    return matchesUser && matchesUnit;
  };

  units.forEach(unit => {
    if (!unit.activities) return;
    if (shouldRemoveUnitActivities(unit)) {
      deletedActivities.push(...unit.activities);
    } else {
      preservedActivities.push(...unit.activities);
    }
  });

  return deletedActivities.filter(item => !preservedActivities.includes(item));
};

export const removeActivities = async activities => {
  try {
    const promises = [];
    for (let i = 0; i < activities.length; i += 1) {
      const activity = activities[i];
      promises.push(caches.delete(activity));
    }
    await Promise.all(promises);
  } catch (err) {
    console.error(err);
  }
};

export const clearCache = async () => {
  try {
    const keys = await caches.keys();
    keys.forEach(key => {
      caches.delete(key);
    });
  } catch (err) {
    console.error(err);
  }
};

export class getUnitsToRemove {}
