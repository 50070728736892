import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ICON_LEFT } from '@oup/shared-front-end/src/svg/oup';
import { useParams } from 'react-router-dom';
import withLocalizedContent from '../../../language/withLocalizedContent';
import MyDownloads from '../../../components/MyDownloads/MyDownloads';
import OfflineUpdate from '../OfflineUpdate/OfflineUpdate';
import styles from './MyDownloadsPage.scss';

function MyDownloadsPage({ localizedContent: { myDownloadsPage: content } }) {
  const [key] = useState('');
  const { userId } = useParams();

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.backButton}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        <ICON_LEFT className={styles.backIcon} />
        {content.back}
      </button>

      <h1 className={styles.title}>{content.title}</h1>
      <MyDownloads key={key} userId={userId} />
      <OfflineUpdate />
    </div>
  );
}

MyDownloadsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('myDownloadsPage'))(MyDownloadsPage);
